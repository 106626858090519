<template>
    <div style="height: 100%; width: 100%;">
        <button @click="getSelectedRows()">Get Selected Rows</button>
        <ag-grid-vue style="width: 100%; height: 100%;"
                     class="ag-grid-theme-builder"
                     :columnDefs="columnDefs"
                     :rowData="rowData"
                    :autoGroupColumnDef="autoGroupColumnDef"
                     @grid-ready="onGridReady">
        </ag-grid-vue>
    </div>
 </template>
 
 <script>
    import {AgGridVue} from "ag-grid-vue";
 
    export default {
        name: "App",
        data() {
            return {
                columnDefs: null,
                rowData: null,
                gridApi: null,
            autoGroupColumnDef: {
                headerName: 'Model',
                field: 'model',
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: {
                    checkbox: true
                }
            }
            };
        },
        components: {
            AgGridVue,
        },
        beforeMount() {
            this.columnDefs = [
               {field: 'make', filter: true, checkboxSelection: true, rowGroup: true },
                {field: 'model', filter: true},
                {field: 'price', filter: true}
            ];
 
            fetch('https://www.ag-grid.com/example-assets/small-row-data.json')
                .then(result => result.json())
                .then(rowData => this.rowData = rowData);
        },
        methods: {
            onGridReady(params) {
                this.gridApi = params.api;
            },
            getSelectedRows() {
                const selectedNodes = this.gridApi.getSelectedNodes();
                const selectedData = selectedNodes.map(node => node.data);
                const selectedDataStringPresentation = selectedData.map(node => `${node.make} ${node.model}`).join(', ');
                alert(`Selected nodes: ${selectedDataStringPresentation}`);
            }
        },
    };
 </script>
 <style lang="scss">
    @import "~ag-grid-community/styles/ag-grid.css";
    @import "../../styles/ag-grid-theme-builder.css";
 </style>